<template>
  <div class="app-banner-image">
    <v-container class="py-16">
      <v-row justify="center">
        <v-col sm="8">
          <p class="app-banner-image__title">
            Welcome to T-Mobile’s Most Powerful Sweepstakes Tool and Lead Nurture Platform
          </p>
        </v-col>
      </v-row>
      <v-row justify="center" class="app-banner-image__row">
        <v-col cols="10" sm="8" lg="6" class="py-0">
          <v-img
            contain
            :src="require('@/assets/' + image)"
            :alt="alt"
            :max-width="maxWidth"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'BannerImage',
  props: {
    image: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: ''
    }
  },
  created() {}
};
</script>

<style lang="scss">
.app-banner-image {
  background-color: var(--v-primary-base);
}
.app-banner-image__row {
  //margin: 45px auto;
  //
  //@media #{map-get($display-breakpoints, 'md-and-up')} {
  //  margin: 95px auto;
  //}
}
.app-banner-image__title {
  color: #fff;
  font-size: 1.3rem;
  text-align: center;
}
</style>
