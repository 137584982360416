import Vue from 'vue';
import VueRouter from 'vue-router';
import AttendeeEngagement from '../views/AttendeeEngagement.vue';
import AttendeeEngagementPreview from '../views/AttendeeEngagementPreview.vue';
import EventCreate from '../views/EventCreate.vue';
import BatchEventCreate from '../views/BatchEventCreate.vue';
import EventEdit from '../views/EventEdit';

import GeneratedRules from '../views/GeneratedRules.vue';
// import Home from '../views/Home.vue';
import AllEvents from '../views/AllEvents.vue';
import Login from '../views/Login.vue';
import LogOut from '../views/LogOut.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import SignUp from '../views/SignUp.vue';
import WebQR from '../views/WebQR.vue';
import WebQRPreview from '../views/WebQRPreview.vue';
import UserApproval from '../views/UserApproval.vue';
import PendingApproval from '../views/PendingApproval.vue';
import Profile from '../views/Profile.vue';
import Resources from '../views/Resources.vue';
import EmailVerified from '../views/EmailVerified.vue';
import CheckIn from '../views/CheckIn.vue';
// import Atoms from '../views/Atoms.vue';
import EnableUser from '../components/Admin/EnableUser.vue';
import LegalSummary from '../views/LegalSummary.vue';
import LegalDashboard from '../views/LegalDashboard.vue';
import AdminDashboard from '../views/AdminDashboard.vue';
import UserEdit from '../views/UserEdit.vue';
import TranslationDashboard from '../views/TranslationDashboard.vue';
import TranslateEvent from '../views/TranslateEvent.vue';
import StaffActions from '../views/StaffActions.vue';
import EventStaffDashboard from '../views/EventStaffDashboard.vue';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/engagements/register/:id',
    name: 'Attendee Engagement',
    component: AttendeeEngagement
  },
  {
    path: '/engagements/preview/:id',
    name: 'Engagement Preview',
    component: AttendeeEngagementPreview
  },
  {
    path: '/event/create',
    name: 'Create Event',
    component: EventCreate
  },
  {
    path: '/event/create/batch',
    name: 'Create Batch Import Event',
    component: BatchEventCreate
  },
  {
    path: '/logout',
    name: 'Log Out',
    component: LogOut
  },
  {
    path: '/password/reset/request/form',
    name: 'Forgot Password',
    component: ForgotPassword
  },
  {
    path: '/event/edit/:id',
    name: 'Edit Event',
    component: EventEdit
  },

  {
    path: '/allevents',
    name: 'View All Events',
    component: AllEvents
  },
  {
    path: '/admin/userapproved',
    name: 'User Approved',
    component: UserApproval
  },
  {
    path: '/admin/userdenied',
    name: 'User Denied',
    component: UserApproval
  },
  {
    path: '/pending',
    name: 'Pending Approval',
    component: PendingApproval
  },
  {
    path: '/dashboard',
    name: 'Data Capture Tool',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/legaldashboard',
    name: 'Legal Dashboard',
    component: LegalDashboard
  },
  {
    path: '/admin',
    name: 'Admin Dashboard',
    component: AdminDashboard
  },
  {
    path: '/translationdashboard',
    name: 'Translation Dashboard',
    component: TranslationDashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/register',
  //   name: 'Sign Up',
  //   component: SignUp
  // },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/webqr/:id',
    name: 'Web QR Code',
    component: WebQR
  },
  {
    path: '/event/rules/:id',
    name: 'Official Rules',
    component: GeneratedRules
  },
  {
    path: '/webqr/preview/:id',
    name: 'Web QR Code Preview',
    component: WebQRPreview
  },
  {
    path: '/emailverified',
    name: 'Email Verified',
    component: EmailVerified
  },
  {
    path: '/checkin/:id',
    name: 'Check In',
    component: CheckIn
  },
  // {
  //   path: '/admin/test',
  //   name: 'testing admin fns',
  //   component: EnableUser
  // },
  // {
  //   path: '/admin/denyUser',
  //   name: 'Admin User Denied',
  //   component: EnableUser
  // },
  // {
  //   path: '/atoms',
  //   name: 'Atoms',
  //   component: Atoms
  // },
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/legal/:id',
    name: 'Legal Summary',
    component: LegalSummary
  },
  {
    path: '/admin/user/edit/:username',
    name: 'User Edit',
    component: UserEdit
  },
  {
    path: '/translate/:id',
    name: 'Translate Event',
    component: TranslateEvent
  },
  {
    path: '/staffactions/:id',
    name: 'Staff Actions',
    component: StaffActions
  },
  {
    path: '/eventstaffdashboard',
    name: 'Event Staff Dashboard',
    component: EventStaffDashboard
  }
];

const router = new VueRouter({
  // mode: 'history',
  routes
});

const passThroughRoutes = [
  // '/login',
  '/password/reset/request/form',
  '/register',
  '/emailverified',
  '/pending',
  '/logout',
  '/checkin'
  // '/atoms'
];

function isStaffRoute(path) {
  return path === '/eventstaffdashboard' || path.startsWith('/staffactions');
}

function dashboard(userGroups) {
  if (userGroups.includes('Admin')) {
    return '/dashboard';
  } else if (userGroups.includes('Legal')) {
    return '/legaldashboard';
  } else if (userGroups.includes('SLTranslator')) {
    return '/translationdashboard';
  } else if (userGroups.includes('Ambassador')) {
    return '/eventstaffdashboard';
  } else {
    return '/dashboard';
  }
}

router.beforeEach(async (to, from, next) => {
  let userInfo = await store.dispatch('account/UPDATE_USER_INFO');
  let isUser = store.getters['account/IS_LOGGED_IN'];
  let userGroups = userInfo.signInUserSession
    ? userInfo.signInUserSession.accessToken.payload['cognito:groups']
    : undefined;
  const isPassThrough =
    passThroughRoutes.includes(to.path) ||
    to.path.startsWith('/engagements/register') ||
    to.path.startsWith('/event/rules');
  try {
    if (isPassThrough) {
      next();
    } else if (!isUser) {
      if (to.path !== '/login') {
        next({
          path: '/login',
          query: { redirectURL: to.path, query: JSON.stringify(to.query) }
        });
      } else {
        next();
      }
    } else {
      if (to.path === '/profile' || to.path === '/resources') {
        next();
      } else if (to.path === '/login' || to.path === '/') {
        next(dashboard(userGroups));
      } else if (userGroups.includes('Admin')) {
        // admins can go anywhere
        next();
      } else if (to.path.startsWith('/admin')) {
        // not allowed
        next(dashboard(userGroups));
      } else if (userGroups.includes('Ambassador')) {
        if (
          isStaffRoute(to.path) ||
          to.path.startsWith('/checkin') ||
          to.path.startsWith('/webqr') ||
          to.path.startsWith('/engagements')
        ) {
          next();
        } else {
          next('/eventstaffdashboard');
        }
      } else if (isStaffRoute(to.path)) {
        next(dashboard(userGroups));
      } else if (userGroups.includes('Legal')) {
        if (
          to.path.startsWith('/legal') ||
          to.path.startsWith('/webqr') ||
          to.path.startsWith('/engagements')
        ) {
          next();
        } else {
          next('/legaldashboard');
        }
      } else if (to.path.startsWith('/legal')) {
        next(dashboard(userGroups));
      } else if (userGroups.includes('SLTranslator')) {
        // yes, the 'e' is intentionally left off.
        if (
          to.path.startsWith('/translat') ||
          to.path.startsWith('/webqr') ||
          to.path.startsWith('/engagements')
        ) {
          next();
        } else {
          next('/translationdashboard');
        }
      } else if (to.path.startsWith('/translat')) {
        next(dashboard(userGroups));
      } else {
        next();
      }
    }
  } catch (error) {
    next('/');
  }
});

export default router;
